<template>
  <div class="cadastro-usuario">

    <h1 class="titulo ">GECOM - {{ user.name }}</h1>
    <v-container
      fluid
      class="pl-0 pr-0"
      style="max-width: 600px;"
    >
      <v-row>
        

        <v-col cols="12" class="pt-0">
          <v-card class="mb-3">
            <v-card-text class="pt-1 pb-1">
              <div class="d-flex justify-space-between align-center">
                <span>
                  {{ getTypeGecom(listTypeGecomFilter.type) }}: {{ listTypeGecomFilter.parent_name }}
                </span>

                <v-btn
                  text
                  @click="voltar()"
                >
                  VOLTAR
                </v-btn>

              </div>
            </v-card-text>
          </v-card>

            <v-expansion-panels v-model="isExpanded">
              <v-expansion-panel>
                <v-expansion-panel-header class="pt-3 pb-0">
                  <v-row>
                    <v-col class="pt-0 pb-0 pr-0">
                      <dateModal :date.sync="date" />
                    </v-col>

                    <v-col class="pt-0 pb-0 pl-0 pr-0">
                      <v-text-field
                        v-model="search"
                        label="Código REV"
                        class="mx-4"
                        hide-details
                        single-line
                        dense
                        @click.stop=""

                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- {{ date | datas('DD/MM/YYYY') }} - {{ entityStart.value }} - {{ entityEnd.value }} -->
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-btn
                      type="submit"
                      :color="variables.colorPrimary"
                      class="br-btn"
                      @click.prevent="submit()"
                      :loading="loading">Processar</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-col cols="4" v-if="isExpanded !== 0" dense class="d-flex align-end"><span>{{ date | datas('DD/MM/YYYY') }}</span></v-col>-->
        </v-col>
      </v-row>

      <div class="box-table box-table-gecom">

        <TabelaRelatorioGecom
          :dataTable="listaItens || []"
          :totais="totais"
          :loadingTable="loadingTable"
          :typeGecom="typeGecom"
          :configGecom="configGecom"
          :search="search"
          :date="date"
          class="mb-5"
          @openProposta="openModalHistorioVendas"
          @openModalEntidade="openModalEntidade"
          @getMaisDetalheGecom="getMaisDetalheGecom"
        />

      </div>
    </v-container>
    <ModalTransmissao />
    <ModalEntidade 
      :isActiveModal="isActiveModal"
      :entityId="entityId"
      @closeModal="closeModalEntidade" 
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { parseTime, errorSnackbar } from '@/core/service/utils'
import moment from 'moment'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

// const namespaceStore = 'relatorioGecomMobile'

export default {
  name: 'Cadastro',
  components: {
    // autoCompleteEntities: () => import('../../components/autoCompleteEntities/gecom'),
    ModalTransmissao: () => import('../../components/modalTransmissoes'),
    TabelaRelatorioGecom: () => import('../../components/tableRelatorioGecom'),
    ModalEntidade: () => import('../../components/modalEntidade'),
    dateModal: () => import('@/views/components/dateModal'),
  },
  mixins: [listagemTables],
  data: () => ({
    isExpanded: null,
    loading: false,
    loadingTable: false,
    entityStart: {},
    entityEnd: {},
    entityRequest: {},
    dtFinal: '',
    dtInicial: '',
    inativos: null,
    hasMonthOrDate: 1,
    headerExcel:  ['Código da Entidade', 'Nome da Entidade', 'Caixa', 'Comissão', 'Deve Anterior', 'Deve Atual', 'Débito', 'Prêmios', 'Proposta', 'Saldo'],

    listTypeGecom: [
      { text: 'Geral', value: null },
      { text: 'Supervisor', value: 2 },
      { text: 'Promotor', value: 3 },
      { text: 'Agência', value: 4 }
    ],
    typeGecom: 4,
    dateInit: '',
    dateEnd: '',
    count: 0,
    date: moment().format('YYYY-MM-DD'),
    isActiveModal: false,
    entityId: 0,
    search: ''
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    transmissoes (val) {
      this.entityRequest = val.entity
    },

    typeGecom () {
      this.clearDados()
    },

    date () {
      this.isExpanded = 0
    }
  },

  mounted () {
    this.dateEnd = this.dtFinal
    this.dateInit = this.dtInicial

    if (this.$route.params.parentPath) {
      this.submit(this.$route.params.parentPath)
    } else {
      this.submit()
    }
  },

  computed: {
    ...mapGetters('relatorioGecomMobile', ['listaItens', 'configGecom', 'totais']),
    ...mapGetters('auth', ['user']),
    listTypeGecomFilter () {
      if (!this.listaItens.length) return false

      if (!this.user.userEntityPaths.length) {
        if (
          (this.user.userEntityPaths.length+1) === 
          (this.listaItens.length && this.listaItens[0].parent_path.split('.').length-1)
        ) {
          return false
        }

      } else if (this.user.userEntityPaths.length === (this.listaItens.length && this.listaItens[0].parent_path.split('.').length-1)) {
        return false
      }

      return { parent_name: this.listaItens[0].parent_name, type:  this.listaItens[0].parent_path.split('.').length-1 }
    },
  },

  methods: {
    ...mapActions('relatorioGecomMobile', ['getItens', 'clearItens']),

    clearDados () {
      this.clearItens()
      this.entityStart = {}
      this.entityEnd = {}
    },
    
    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::transmissoes', { 
        data: val,
        entity: this.entityRequest,
        hasMonthOrDate: this.hasMonthOrDate,
        dtFinal: this.dtFinal,
        dtInicial: this.dtInicial 
      })
    },

    opemDadosJogosWinners(val) {
      Events.$emit('modal::historicoVendas::jogosWinners', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosCaixa(val) {
      Events.$emit('modal::historicoVendas::caixa', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosPgto(val) {
      Events.$emit('modal::historicoVendas::pgto', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    openModalHistorioVendas(val) {
      const data = {
        entityId: val.entity_id,
        entity_cod: val.cod,
        dtStart: this.date,
        dtEnd: this.date,
        hasMonthOrDate: this.hasMonthOrDate
      }

      Events.$emit('modal::historicoVendas::transmissoes', data)
    },

    init() {},

    submit (parentPath) {
      this.loading = true
      this.loadingTable = true

      this.getItens({ date: this.date, parentPath })
      .catch(err => {
        this.clearDados()
        errorSnackbar(err.error)
      })
      .finally(() => {
        this.loading = false
        this.loadingTable = false
        this.count++
        this.isExpanded = null
      })

    },

    getMaisDetalheGecom (parentPath) {
      this.submit(parentPath)
    },

    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const header = this.headerExcel
        const filterVal = ['entity_cod', 'entity_name', 'caixa', 'comissao', 'deve_ant', 'deve_atual', 'pagto', 'premio', 'proposta', 'saldo']
        const data = this.formatJson(filterVal)
        excel.export_json_to_excel({
          header,
          data,
          filename: 'gecom',
          autoWidth: true
        })
        this.downloadLoading = false
      })
    },
    
    formatJson(filterVal) {
      let data = []
      switch (this.typeGecom) {
        case (null) :
          data = this.transmissoes.results
          break

        case (2) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`
              data.push(promotor)
            })
          })

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break

        case (3) :
        case (4) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`

              promotor.agencias.forEach(agencia => {
                agencia.supervisor = promotor.supervisor
                agencia.promotor = `${promotor.entity_cod} - ${promotor.entity_name}`
                data.push(agencia)
              })
            })
          })

          filterVal.unshift('promotor')
          this.headerExcel.unshift('Promotor')

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break
      }

      return data.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },

    closeModalEntidade (val) {
      this.isActiveModal = !val
    },

    openModalEntidade (val) {
      this.entityId = val.entity_id
      this.isActiveModal = true
    },

    voltar() {
      if (this.loadingTable) return

      const parentPathArray = this.listaItens[0].parent_path.split('.')
      parentPathArray.pop()
      parentPathArray.pop()

      if (!parentPathArray.length) {
        this.$router.push({ name: 'gecom-home' })
        return
      }

      this.submit(parentPathArray.join('.'))
    },

    getTypeGecom (type) {
     return ['Geral', 'Supervisor', 'Promotor'][type-1]
    }
  },

  beforeDestroy () {}
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;


    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding: 10px 5px;
        font-size: 11px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
